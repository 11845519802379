<template lang="pug">
    #cursos
        ul
            li(v-for="item in contents.content")
                h3.title {{ item.title }}
                .container
                    .desc
                        p <strong> Descrição: </strong> {{ item.description }}
                    hr
                    .date
                        p <strong> Data/horário: </strong>{{ item.date }}
                    hr
                    .local
                        p <strong> Local: </strong>{{ item.local }}
                        a(:href="item.link" target="_blank").squareButton TENHO INTERESSE 
</template>

<script>
import { props } from '@/mixins/component'

export default {
    name: 'cursos-e-treinamentos',
    props,
}
</script>

<style lang="stylus" scoped src="./Cursos.styl"></style>